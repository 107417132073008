.imagefields{
    text-align: center;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: #FFF200 solid 5px;
}

#finput{
    margin: 25px 0;
    padding-left: 6%;
}

.ffl{
    text-align: center;
}

.imagefields > textarea{
    width: 50%;
    height: 50px;
}

#fotosa{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#fotolijst{
    width: 90%;
    text-align: center;
}

.flitem{
    width: 25%;
    display: inline-grid;
    margin: 25px;
    padding: 5px;
    color: white;
}
.flitem > p{
    width: 90%;
    margin: 0 5%;
    text-align: center;
}

.flitem > img{
    width: 100%;
}

@media screen and (max-width: 1500px) {
    .flitem{
        width: 40%;
    }

}

@media screen and (max-width: 1250px) {
    .flitem{
        width: 60%;
    }
}

@media screen and (max-width: 900px) {
    .flitem{
        width: 80%;

    }
    #fotosa > .imagefields > img{
        width: 80% !important;
    }
    #fotosa > .imagefields > textarea{
        width: 80%;
    }
}