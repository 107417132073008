body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

header{
  background-color: white;
  display: flex;
  justify-content: center;
  border-bottom: black solid 5px;
}
.main{
  background-color: #D3D3D3b3;
}

.boot, .fotog{
  border: none;
  box-shadow: none;
}

footer{
  min-height: 50px;
  background-color: #A1A2A6;
  border-top: black solid 5px;
}

body{
  background: linear-gradient(lightgray, lightgray), url("./img/Background.jpg") no-repeat fixed center ;
  background-blend-mode: saturation;
  background-size: 100% 70%;
}

header{
  background-color: lightgray;
  display: flex;
  justify-content: center;
  border-bottom: #FFF200 solid 5px;
}

footer{
  min-height: 50px;
  background-color: #A1A2A6;
  border-top: #FFF200 solid 5px;
}

.logo{
  width: 20%;
}

.nav{
  width: 60%;
  margin: 20px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-decoration: none;
}

.nav > h2 > a{
  color: black;
  font-size: 26px;
  text-decoration: none;
}

.nav > h2 > a:hover{
  text-decoration: underline;
  color: black;
  text-shadow: rgb(161,162,166) 2px 2px;
}

.main{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  background-color: rgba(161,162,166, 0.7);
  margin: 50px 20%;
  padding: 30px 0;
}

.login{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  background-color: rgba(161,162,166, 0.7);
  margin: 150px 20%;
  padding: 30px 0;
}

h1{
  color: #FFF200;
  margin: 5px;
  text-decoration: underline;
  text-shadow: black 5px 3px;
  text-align: center;
}

.line{
  border-bottom: black 5px solid;
  width: 60%;
  margin: 40px 0;
}

.image-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  min-width: 70%;
}

.image-popup img {
  max-height: 80vh;
  display: block;
  margin: 0 auto; /* Center the image horizontally */
  max-width: 100%;
}

.image-popup button {
  display: block;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}


@media screen and (max-width: 1000px) {

  .logo{
    width: 25%;
  }

  .nav{
    width: 80%;
    justify-content: space-between;
  }

  .nav > h2 > a{
    font-size: 22px;
  }
}

@media screen and (max-width: 600px) {

  .logo{
    width: 30%;
  }

  .nav{
    width: 95%;
    justify-content: space-between;
  }

  .nav > h2 > a{
    font-size: 18px;
  }

}

@media screen and (max-width: 500px) {

  .nav{
    width: 100%;
    justify-content: space-evenly;
  }

  .nav > h2 > a{
    font-size: 14px;
  }

  h1{
    font-size: 28px;
  }

  .main{
    width: 90%;
    margin: 50px 5%;
  }

  .login{
    width: 80%;
    margin: 150px 10%;
  }
}