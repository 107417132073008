.aankondiging{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
}

.aankondiging > p{
    color: #FFF200;
}

#abeschrijving, #acabeschrijving{
    width: 50%;
    height: 100px;
}

#tbeschrijving, #actbeschrijving{
    width: 50%;
}

button{
    background: yellow;
    padding: 5px 50px;
    border-radius: 25px;
}

.ab{
    margin-bottom: 25px;
}

.imagefieldsa{
    text-align: center;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    padding-bottom: 25px;
}