#pdfPreviewContainer{
    width: 80%;
    margin-bottom: 25px;
}

#pdfPreviewContainer > iframe{
    height: 700px;
    text-align: center;
}

.rglh1{
    font-size: 40px;
    margin-bottom: 25px;
}



@media screen and (max-width: 750px) {
    .rglh1{
        font-size: 28px;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 550px) {
    .rglh1{
        margin-bottom: 5px;
    }
}