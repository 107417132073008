p{
    width: 80%;
    text-align: center;
    font-size: 20px;
}


header{
    background-color: white;
    display: flex;
    justify-content: center;
    border-bottom: black solid 5px;
}
.main{
    background-color: #D3D3D3b3;
}

.boot, .fotog{
    border: none;
    box-shadow: none;
}

footer{
    min-height: 50px;
    background-color: #A1A2A6;
    border-top: black solid 5px;
}

.boot{
    width: 50%;
    margin: 25px 0;
}

.fotos{
    display: block;
    width: 90%;
    text-align: center;
    margin-top: 35px;
}


.fotog{
    width: 40%;
    margin: 10px 3%;
}

.errorm{
    font-size: 16px;
    color: red;
}

.infohref{
    color: black;
    text-decoration: underline;
}

.infohref:hover{
    color: #FFF200;
}

.beheer{
    border: #FFF200 solid 5px;
    width: 70%;
    text-align: center;
    padding-bottom: 25px;
    margin-bottom: 50px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
}

.login > h1{
    font-size: 48px;
    margin-bottom: 25px;
}

.btnl{
    margin-top: 20px;
}

.login > .logo{
    width: 30%;
}

.login > div > p{
    text-align: left;
    color: #FFF200;
    margin-bottom: 10px;
}

.login > div > input{
    width: 250px;
}

#aankondigingdiv, #activiteitendiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: #FFF200 solid 5px;
    margin-bottom: 25px;
}

#activiteitendiv{
    border-top: #FFF200 solid 5px;
    margin-top: 35px;
}

#aankondigingdiv > img{
    width: 40%;
    margin: 25px 0;
}

#aankondigingdiv > p, #activiteitendiv > p{
    width: 80%;
}


.loading-icon {
    width: 100%;
    font-size: 40px; /* Adjust the size as needed */
    margin-bottom: 10px;
    animation: spin 1s infinite linear; /* Add spinning animation */
    color: #FFF200;
}

.loading-message{
    width: 100%;
    color: #FFF200;
    font-weight: bold;
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}


.df{
    margin-top: 15px;
    margin-bottom: 10px;
}

@media screen and (max-width: 1000px) {

    .boot{
        width: 70%;
    }

    .fotos{
        width: 90%;
    }

    .fotog{
        width: 70%;
    }

    .login > .logo{
        width: 40%;
    }

}

@media screen and (max-width: 750px) {
    .boot{
        width: 80%;
    }

    .fotos{
        width: 90%;
    }

    .fotog{
        width: 80%;
    }

    p{
        width: 90%;
        font-size: 18px;
    }

    .login > .logo{
        width: 60%;
    }
}

@media screen and (max-width: 500px) {
    .boot{
        width: 90%;
    }

    .fotos{
        width: 90%;
    }

    .fotog{
        width: 90%;
    }
}